import React from 'react';
import '../css/style.css';  // Importa o estilo

const Layout = ({ children }) => {
  return (
    <>
      <div className="content">
        {children} {/* Renderiza o conteúdo passado como filho */}
      </div>
    </>
  );
};

export default Layout;
